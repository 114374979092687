























































































































import { Component, Ref } from "vue-property-decorator";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import VesselAutocomplete from "@/components/common/vessel/VesselAutocomplete.vue";
import VesselEnquiryCertificateCreateTable, {
  RequiredCertificates
} from "@/components/vessel/enquiry/VesselEnquiryCertificateCreateTable.vue";
import VesselEnquiryJobRoleTable, {
  JobRoleDescription
} from "@/components/vessel/enquiry/VesselEnquiryJobRoleTable.vue";
import VesselEnquiryJobRoleAddDialog from "@/components/dialogs/VesselEnquiryJobRoleAddDialog.vue";
import VesselEnquiryCertificateAddDialog from "@/components/dialogs/VesselEnquiryCertificateAddDialog.vue";
import Api from "@/api";
import { VesselEnquiryCreateModel } from "@/api/generated";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    VesselAutocomplete,
    VesselEnquiryCertificateCreateTable,
    VesselEnquiryJobRoleTable,
    VesselEnquiryJobRoleAddDialog,
    VesselEnquiryCertificateAddDialog
  }
})
export default class VesselEnquiryCreate extends Validation {
  @Ref() private form!: VForm;

  private loading = false;
  private tab = 0;
  private jobRoleTableRefreshKey = false;
  private certificateTableRefreshKey = false;
  private addJobRoleDialog = false;
  private addCertificateDialog = false;
  private currency = false;
  private enquiry: VesselEnquiryCreateModel = {
    vesselId: "",
    startDate: "",
    endDate: "",
    monthlyFeeGbp: 0,
    crewMemberVesselEnquiries: [],
    requiredCertificates: [],
    isBritishPounds: false
  };

  private toggleCurrency() {
    this.currency = !this.currency;
  }

  private async onCreateVesselEnquiry() {
    if (!this.form.validate()) {
      return;
    }

    this.enquiry.crewMemberVesselEnquiries?.forEach(x => {
      x.startDate = this.enquiry.startDate;
      x.endDate = this.enquiry.endDate;
    });

    this.enquiry.isBritishPounds = this.currency;

    try {
      this.loading = true;
      const response = await Api.VesselEnquiryService.apiVesselenquiryPost(
        this.enquiry
      );

      if (!response.data.vesselEnquiryId) {
        this.$router.replace({ name: "Vessel Enquiry Overview" });
        return;
      }

      snackbarModule.setSnackbarMessage("Vessel enquiry created");

      this.$router.replace({
        name: "Vessel Enquiry Edit",
        params: { vesselEnquiryId: response.data.vesselEnquiryId }
      });
    } finally {
      this.loading = false;
    }
  }

  private onAddJobRole() {
    this.addJobRoleDialog = true;
  }

  private onDeleteJobRole(jobRole: JobRoleDescription) {
    this.enquiry.crewMemberVesselEnquiries = this.enquiry.crewMemberVesselEnquiries?.filter(
      x => x.jobRoleId !== jobRole.jobRoleId
    );
  }

  private onAddCertificate() {
    this.addCertificateDialog = true;
  }

  private onCeritifcateAdded(item: RequiredCertificates) {
    this.enquiry.requiredCertificates?.push(item);
    this.certificateTableRefreshKey = !this.certificateTableRefreshKey;
  }

  private onDeleteCertificate(item: RequiredCertificates) {
    this.enquiry.requiredCertificates = this.enquiry.requiredCertificates?.filter(
      x => x.certificateTypeId !== item.certificateTypeId
    );
  }

  private onJobRoleAdded(jobRole: JobRoleDescription) {
    for (let index = 0; index < jobRole.quantity; index++) {
      this.enquiry.crewMemberVesselEnquiries?.push(jobRole);
    }

    this.addJobRoleDialog = false;
    this.jobRoleTableRefreshKey = !this.jobRoleTableRefreshKey;
  }
}
